<template>
  <div>
    <div v-if="loading" class="text-center">
      <Loading />
      <h2>{{ title }}</h2>
    </div>
    <div v-else>
      <b-modal
        id="whats-2"
        title="Certificado gerado com sucesso!"
        @ok="sendByWhatsapp"
        @cancel="$router.push('/dashboard')"
        ok-title="sim"
        cancel-title="não"
      >
        <p>
          Enviamos instruções para o email cadastrado. Quer enviar também por
          WhatsApp?
        </p>
      </b-modal>
      <q-card>
        <OrderListGroup :order="order" />
      </q-card>
      <q-card>
        <div class="item" v-if="getCustomerName(order)">
          <div class="title">Paciente</div>
          <div class="body">{{ getCustomerName(order) }}</div>
        </div>

        <div class="item">
          <div class="title">Ótica</div>
          <div class="body">
            {{ store.name }}
            <span v-if="store.address">
              {{ store.address.street ? `${store.address.street},` : '' }}
              {{ store.address.city ? `${store.address.city},` : '' }}
              {{ store.address.zip ? `${store.address.zip},` : '' }} WhatsApp:
              {{ store.whatsapp }}, Telefone: {{ store.phone }}
            </span>
          </div>
        </div>

        <div class="item">
          <div class="title">Data da venda</div>
          <div class="body">
            {{ order.created_at.split('T')[0].split('-').reverse().join('/') }}
          </div>
        </div>
        <!-- 
        <div class="item">
          <div class="title">Produto</div>
          <div class="body">
            {{ getProduct(order.details.prescription) }}
          </div>
        </div> -->

        <div
          class="item"
          v-if="getProductPrescription(order.details.prescription, 'RIGHT')"
        >
          <div class="title">Olho Direito</div>
          <div class="body">
            {{ getProductPrescription(order.details.prescription, 'RIGHT') }}
          </div>
        </div>

        <div
          class="item"
          v-if="
            getProductPrescriptionComp(
              order.details.externalSystemPrescriptions,
              'RIGHT'
            ) !== ''
          "
        >
          <div class="title">Dioptria Compensada Olho Direito</div>
          {{
            getProductPrescriptionComp(
              order.details.externalSystemPrescriptions,
              'RIGHT'
            )
          }}
        </div>

        <div
          class="item"
          v-if="getProductPrescription(order.details.prescription, 'LEFT')"
        >
          <div class="title">Olho Esquerdo</div>
          <div class="body">
            {{ getProductPrescription(order.details.prescription, 'LEFT') }}
          </div>
        </div>

        <div
          class="item"
          v-if="
            getProductPrescriptionComp(
              order.details.externalSystemPrescriptions,
              'LEFT'
            ) !== ''
          "
        >
          <div class="title">Dioptria Compensada Olho Esquerdo</div>
          <div class="body">
            {{
              getProductPrescriptionComp(
                order.details.externalSystemPrescriptions,
                'LEFT'
              )
            }}
          </div>
        </div>

        <div class="item" v-if="getDefaultMeasures(order)">
          <div class="title">Medidas Personalizadas</div>
          <div class="body">
            {{ getDefaultMeasures(order) }}
          </div>
        </div>

        <div class="item" v-if="getProducts(order)">
          <div class="title">Outros</div>
          <div class="body">
            {{ getProducts(order) }}
          </div>
        </div>

        <div class="item" v-if="getDoctorName(order)">
          <div class="title">Médico</div>
          <div class="body">{{ getDoctorName(order) }}</div>
        </div>
      </q-card>
      <OrderActivity
        v-if="order.details.orderTracking && 1 === 2"
        class="mt-10"
        :routes="order.details.orderTracking"
      />
      <ProductCustomer :name="name" @input="setCustomer" />
      <ProductDoctor @input="setDoctor" :doc="doctor" />
      <br />
      <div
        v-if="canCharge"
        style="
          background: #ffb837;
          padding: 10px;
          margin-bottom: 11px;
          border-radius: 5px;
          color: #424242;
        "
      >
        Este certificado é pago pela ótica e será debitado do saldo de
        certificados personalizados. Deseja continuar?
      </div>
      <b-button block variant="primary" size="lg" @click="save">
        <div>ENVIAR NOTIFICAÇÃO</div>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrdersPageForm',

  data: () => ({
    id: null,
    name: null,
    title: 'Carregando...',
    doctor: {
      name: null,
      document: null
    },
    phone: null,
    showModal: false,
    loading: true,
    order: null,
    esf: 'Esférico',
    cil: 'Cilíndrico',
    add: 'Adição',
    axis: 'Eixo',
    height: 'Altura',
    dnp: 'DNP',
    vert: 'Dis. Vértice'
  }),

  computed: {
    customer() {
      return this.$store.state.orders.newOrder.customer
    },

    store() {
      return this.$store.state.auth.store
    },

    items() {
      return this.order
    },

    canCharge() {
      let canCharge = false
      if (this.order.details) {
        if (this.order.details.prescription) {
          if (this.order.details.prescription.eyes) {
            this.order.details.prescription.eyes.filter((item) => {
              if (item.productLine) {
                canCharge = true
              }
            })
          }
        }
      }

      return canCharge
    }
  },

  methods: {
    setDoctor(doctor) {
      this.doctor = doctor
    },

    getProducts(order) {
      if (order.details) {
        if (!order.details.products || order.details.products.length === 0) {
          return false
        }
      }

      const products = order.details.products
        .map((prod) => prod.description)
        .join(' | ')

      if (products) {
        return products
      }
      return null
    },

    getDefaultMeasures(order) {
      if (!order.details.externalSystemPrescriptions) {
        return false
      }

      if (order.details.externalSystemPrescriptions.length === 0) {
        return false
      }

      const eye = order.details.externalSystemPrescriptions.filter(
        (eye) => eye.eye === 'LEFT'
      )[0]

      let response = null

      if (eye.pantoscopicAngle) {
        response = `Pantoscópico: ${eye.pantoscopicAngle.toFixed(2)}`
      }

      if (eye.curvatureAngle) {
        response = `Curva: ${eye.curvatureAngle.toFixed(2)}`
      }

      if (eye.curvatureAngle && eye.pantoscopicAngle) {
        response = `Curva: ${eye.curvatureAngle.toFixed(
          2
        )} Pantoscópico: ${eye.pantoscopicAngle.toFixed(2)}`
      }

      return response
    },

    setCustomer(val) {
      this.name = val.name
    },

    getProduct(presc) {
      const right = this.getProductPrescriptionData(presc, 'RIGHT')
      const left = this.getProductPrescriptionData(presc, 'LEFT')

      if (right.productLine) {
        return `${right.productLine.description}`
      }

      if (right.productLine.description !== left.productLine.description) {
        return `OD: ${right.productLine.description} | OE: ${left.productLine.description}`
      }

      return null
    },

    getField(label, value) {
      if (label === this.height || label === this.dnp || label === this.vert) {
        if (value) {
          return `${label}: ${value.toFixed(1)}`
        }
      }
      if (label === this.esf && value === 0) {
        return `${label}: ${value.toFixed(2)}`
      }
      if (label === this.add && value === 0) {
        return `${label}: ${value.toFixed(2)}`
      }
      if (label === this.axis) {
        if (value) {
          return `${label}: ${parseInt(value)}`
        }
      }
      if (value) {
        if (value > 99) {
          return `${label}: ${value}`
        } else if (value < 0) {
          return `${label}: ${value.toFixed(2)}`
        } else {
          return `${label}: ${value.toFixed(2)}`
        }
      }

      return ''
    },

    getProductPrescription(presc, side) {
      const {
        farSpherical,
        farCylindrical,
        axis,
        addition,
        nearSpherical,
        nearCylindrical,
        fittingHeight,
        interPupillaryDistance
      } = this.getProductPrescriptionData(presc, side)

      if (
        (farSpherical === 0 && addition === null) ||
        (farSpherical === 0 && addition === 0)
      ) {
        return `${this.getField(this.esf, farSpherical)} ${this.getField(
          this.cil,
          farCylindrical
        )} ${this.getField(this.axis, axis)} ${this.getField(
          this.add,
          addition
        )} ${this.getField(this.axis, axis)} ${this.getField(
          this.height,
          fittingHeight
        )} ${this.getField(this.axis, axis)} ${this.getField(
          this.dnp,
          interPupillaryDistance
        )}`
      }

      if (
        (addition === null && farCylindrical !== 0) ||
        (addition === null && farCylindrical !== null)
      ) {
        return `${this.getField(this.esf, farSpherical)} ${this.getField(
          this.cil,
          farCylindrical
        )} ${this.getField(this.axis, axis)} ${this.getField(
          this.height,
          fittingHeight
        )} ${this.getField(this.dnp, interPupillaryDistance)}`
      }

      if (
        (addition === null && farCylindrical === 0) ||
        (addition === null && farCylindrical === null)
      ) {
        return `${this.getField(this.esf, nearSpherical)} ${this.getField(
          this.cil,
          nearCylindrical
        )} ${this.getField(this.axis, axis)} ${this.getField(
          this.height,
          fittingHeight
        )} ${this.getField(this.dnp, interPupillaryDistance)}`
      }

      if (addition !== 0 || addition !== null) {
        return `${this.getField(this.esf, farSpherical)} ${this.getField(
          this.cil,
          farCylindrical
        )} ${this.getField(this.axis, axis)} ${this.getField(
          this.add,
          addition
        )} ${this.getField(this.height, fittingHeight)} ${this.getField(
          this.dnp,
          interPupillaryDistance
        )}`
      }

      if (addition === null && farCylindrical !== 0) {
        return `${this.getField(this.esf, farSpherical)} ${this.getField(
          this.cil,
          farCylindrical
        )} ${this.getField(this.axis, axis)} ${this.getField(
          this.height,
          fittingHeight
        )} ${this.getField(this.dnp, interPupillaryDistance)}`
      }

      if (
        (addition === null && farCylindrical === 0) ||
        farCylindrical === null
      ) {
        return `${this.getField(this.esf, nearSpherical)} ${this.getField(
          this.cil,
          nearCylindrical
        )}  ${this.getField(this.axis, axis)} ${this.getField(
          this.height,
          fittingHeight
        )} ${this.getField(this.dnp, interPupillaryDistance)}`
      }

      return false
    },

    isEmpty(str) {
      return str.length === 0 || !str.trim()
    },

    getProductPrescriptionComp(presc, side) {
      const items = this.getProductPrescriptionDataComp(presc, side)
      if (!items) {
        return null
      }
      const {
        farSpherical,
        farCylindrical,
        axis,
        addition,
        nearSpherical,
        nearCylindrical,
        fittingHeight,
        interPupillaryDistance,
        leftVerticeDistance,
        rightVerticeDistance
      } = items

      let verticeDistance = leftVerticeDistance

      if (side === 'RIGHT') {
        verticeDistance = rightVerticeDistance
      }

      if (
        (farSpherical === 0 && addition === null) ||
        (farSpherical === 0 && addition === 0)
      ) {
        const response = `${this.getField(
          this.esf,
          farSpherical
        )} ${this.getField(this.cil, farCylindrical)} ${this.getField(
          this.axis,
          axis
        )} ${this.getField(this.add, addition)} ${this.getField(
          this.height,
          fittingHeight
        )} ${this.getField(this.dnp, interPupillaryDistance)}

        ${this.getField(this.vert, verticeDistance)}`

        if (response.trim() === '') {
          return null
        }

        return response
      }

      if (
        (addition === null && farCylindrical !== 0) ||
        (addition === null && farCylindrical !== null)
      ) {
        const response = `${this.getField(
          this.esf,
          farSpherical
        )} ${this.getField(this.cil, farCylindrical)} ${this.getField(
          this.axis,
          axis
        )} ${this.getField(this.vert, verticeDistance)}`

        if (response.trim() === '') {
          return null
        }

        return response
      }

      if (
        (addition === null && farCylindrical === 0) ||
        (addition === null && farCylindrical === null)
      ) {
        const response = `${this.getField(
          this.esf,
          nearSpherical
        )} ${this.getField(this.cil, nearCylindrical)} ${this.getField(
          this.axis,
          axis
        )} ${this.getField(this.height, fittingHeight)} ${this.getField(
          this.dnp,
          interPupillaryDistance
        )} ${this.getField(this.vert, verticeDistance)}`

        if (response.trim() === '') {
          return null
        }

        return response
      }

      if (addition !== 0 || addition !== null) {
        const response = `${this.getField(
          this.esf,
          farSpherical
        )} ${this.getField(this.cil, farCylindrical)} ${this.getField(
          this.axis,
          axis
        )} ${this.getField(this.add, addition)} ${this.getField(
          this.height,
          fittingHeight
        )} ${this.getField(this.dnp, interPupillaryDistance)} ${this.getField(
          this.vert,
          verticeDistance
        )}`

        if (response.trim() === '') {
          return null
        }

        return response
      }

      if (addition === null && farCylindrical !== 0) {
        const response = `${this.getField(
          this.esf,
          farSpherical
        )} ${this.getField(this.cil, farCylindrical)} ${this.getField(
          this.axis,
          axis
        )} ${this.getField(this.height, fittingHeight)} ${this.getField(
          this.dnp,
          interPupillaryDistance
        )} ${this.getField(this.vert, verticeDistance)}`

        if (response.trim() === '') {
          return null
        }

        return response
      }

      if (
        (addition === null && farCylindrical === 0) ||
        farCylindrical === null
      ) {
        const response = `${this.getField(
          this.esf,
          nearSpherical
        )} ${this.getField(this.cil, nearCylindrical)}  ${this.getField(
          this.axis,
          axis
        )} ${this.getField(this.height, fittingHeight)} ${this.getField(
          this.dnp,
          interPupillaryDistance
        )} ${this.getField(this.vert, verticeDistance)}`
        if (response.trim() === '') {
          return null
        }

        return response
      }

      return false
    },

    getProductPrescriptionData(presc, side) {
      const eye = presc.eyes.filter((item) => item.eye === side)[0]
      if (eye) {
        return eye
      }
      return null
    },
    getProductPrescriptionDataComp(presc, side) {
      if (!presc) {
        return false
      }
      const eye = presc.filter((item) => item.eye === side)[0]
      if (eye) {
        return eye
      }
      return null
    },

    getCustomerName(order) {
      if (order.data) {
        if (order.data.orderMembers) {
          if (order.data.orderMembers.length > 0) {
            const customer = order.data.orderMembers.filter(
              (item) => item.orderMemberType.description === 'PACIENTE'
            )
            if (customer.length == 1) {
              return customer[0].name
            }
          }
        }
      }

      if (order.customer) {
        if (order.customer.name) {
          return order.customer.name
        }
      }

      return false
    },

    getDoctorName(order) {
      if (order.data) {
        if (order.data.orderMembers) {
          if (order.data.orderMembers.length > 0) {
            const doctor = order.data.orderMembers.filter(
              (item) => item.orderMemberType.description === 'MEDICO'
            )
            if (doctor.length == 1) {
              this.doctor.name = doctor[0].name
              this.doctor.document = `${doctor[0].crm} - ${doctor[0].crmState}`
              return `${doctor[0].name} | ${doctor[0].crm} - ${doctor[0].crmState}`
            }
          }
        }
      }

      if (order.customer) {
        if (order.customer.name) {
          return order.customer.name
        }
      }

      return false
    },

    save() {
      this.title = 'Enviando...'
      this.loading = true
      let item = {
        id: this.$route.params.id,
        customer: { ...this.customer },
        doctor: { ...this.doctor },
        canCharge: this.canCharge === true ? 1 : 0
      }
      if (item.doctor.name === false) {
        item.doctor.name = null
      }
      if (item.doctor.document === false) {
        item.doctor.document = null
      }
      this.$http
        .post('certificate/generate', item)
        .then(({ data }) => {
          this.id = data.id
          this.phone = item.customer.phone
          if (item.customer.phone) {
            this.$bvModal.show('whats-2')
          }
          this.fetchOrders()
          this.fetchCertificates()
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.title = 'Carregando...'
          this.loading = false
        })
    },

    loadOrder() {
      this.$http
        .get(`orders/${parseInt(this.$route.params.id)}`)
        .then((res) => {
          this.order = res.data
          this.name = this.getCustomerName(this.order)
        })
        .catch(() => {
          this.loading = false
          this.$alert('Ocorreu um problema carregar este pedido.')
          // this.$router.push('/ordens')
        })
        .finally(() => (this.loading = false))
    },

    sendByWhatsapp() {
      const suffix = process.env.DEV ? 'local' : 'app'
      const url = `https://api.opticonecta.${suffix}/show`
      const number = this.phone
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '')

      window.location.href = `https://api.whatsapp.com/send?phone=55${number}&text=${url}/${this.id}`
    }
  },

  mounted() {
    this.loadOrder()
  }
}
</script>

<style lang="scss" scoped>
.item {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
}
.item > div.title {
  font-weight: bold;
  font-size: 1.2em;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('Loading'),_c('h2',[_vm._v(_vm._s(_vm.title))])],1):_c('div',[_c('b-modal',{attrs:{"id":"whats-2","title":"Certificado gerado com sucesso!","ok-title":"sim","cancel-title":"não"},on:{"ok":_vm.sendByWhatsapp,"cancel":function($event){return _vm.$router.push('/dashboard')}}},[_c('p',[_vm._v(" Enviamos instruções para o email cadastrado. Quer enviar também por WhatsApp? ")])]),_c('q-card',[_c('OrderListGroup',{attrs:{"order":_vm.order}})],1),_c('q-card',[(_vm.getCustomerName(_vm.order))?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Paciente")]),_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.getCustomerName(_vm.order)))])]):_vm._e(),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Ótica")]),_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.store.name)+" "),(_vm.store.address)?_c('span',[_vm._v(" "+_vm._s(_vm.store.address.street ? ((_vm.store.address.street) + ",") : '')+" "+_vm._s(_vm.store.address.city ? ((_vm.store.address.city) + ",") : '')+" "+_vm._s(_vm.store.address.zip ? ((_vm.store.address.zip) + ",") : '')+" WhatsApp: "+_vm._s(_vm.store.whatsapp)+", Telefone: "+_vm._s(_vm.store.phone)+" ")]):_vm._e()])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Data da venda")]),_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.order.created_at.split('T')[0].split('-').reverse().join('/'))+" ")])]),(_vm.getProductPrescription(_vm.order.details.prescription, 'RIGHT'))?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Olho Direito")]),_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.getProductPrescription(_vm.order.details.prescription, 'RIGHT'))+" ")])]):_vm._e(),(
          _vm.getProductPrescriptionComp(
            _vm.order.details.externalSystemPrescriptions,
            'RIGHT'
          ) !== ''
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Dioptria Compensada Olho Direito")]),_vm._v(" "+_vm._s(_vm.getProductPrescriptionComp( _vm.order.details.externalSystemPrescriptions, 'RIGHT' ))+" ")]):_vm._e(),(_vm.getProductPrescription(_vm.order.details.prescription, 'LEFT'))?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Olho Esquerdo")]),_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.getProductPrescription(_vm.order.details.prescription, 'LEFT'))+" ")])]):_vm._e(),(
          _vm.getProductPrescriptionComp(
            _vm.order.details.externalSystemPrescriptions,
            'LEFT'
          ) !== ''
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Dioptria Compensada Olho Esquerdo")]),_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.getProductPrescriptionComp( _vm.order.details.externalSystemPrescriptions, 'LEFT' ))+" ")])]):_vm._e(),(_vm.getDefaultMeasures(_vm.order))?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Medidas Personalizadas")]),_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.getDefaultMeasures(_vm.order))+" ")])]):_vm._e(),(_vm.getProducts(_vm.order))?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Outros")]),_c('div',{staticClass:"body"},[_vm._v(" "+_vm._s(_vm.getProducts(_vm.order))+" ")])]):_vm._e(),(_vm.getDoctorName(_vm.order))?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("Médico")]),_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.getDoctorName(_vm.order)))])]):_vm._e()]),(_vm.order.details.orderTracking && 1 === 2)?_c('OrderActivity',{staticClass:"mt-10",attrs:{"routes":_vm.order.details.orderTracking}}):_vm._e(),_c('ProductCustomer',{attrs:{"name":_vm.name},on:{"input":_vm.setCustomer}}),_c('ProductDoctor',{attrs:{"doc":_vm.doctor},on:{"input":_vm.setDoctor}}),_c('br'),(_vm.canCharge)?_c('div',{staticStyle:{"background":"#ffb837","padding":"10px","margin-bottom":"11px","border-radius":"5px","color":"#424242"}},[_vm._v(" Este certificado é pago pela ótica e será debitado do saldo de certificados personalizados. Deseja continuar? ")]):_vm._e(),_c('b-button',{attrs:{"block":"","variant":"primary","size":"lg"},on:{"click":_vm.save}},[_c('div',[_vm._v("ENVIAR NOTIFICAÇÃO")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }